<template>
	<div class="menu-item border-bottom" @click="click">
		<slot></slot>
	</div>
</template>

<script>
	import store from "@/store";

	export default {
		name: "MenuItem",
		props: {
			data: Object,
		},
		methods: {
			click() {
				console.log("data-item", this.data);
				store.commit("hMenu/setClickItem", this.data);
			},
		},
	};
</script>

<style scoped>
	.menu-item {
		height: 50px;
		line-height: 50px;
		margin-left: 15px;
	}

	.menu-item:hover {
		color: #00cfe8;
	}
</style>
