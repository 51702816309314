<template>
	<div class="sub-menu border-bottom" @mouseenter="showSubMenu" @mouseleave="hideSubMenu">
		<div class="title">
			<slot name="title"></slot>
			<span class="icon">
				<b-icon-chevron-right v-if="data.children && data.children.length > 0"></b-icon-chevron-right>
			</span>
		</div>
		<Transition>
			<div v-show="subShow" class="sub-item">
				<b-card class="border" no-body style="margin-left: 0.1rem">
					<slot></slot>
				</b-card>
			</div>
		</Transition>
	</div>
</template>

<script>
	import { ref } from "@vue/composition-api/dist/vue-composition-api";

	export default {
		name: "SubMenu",
		props: {
			data: Object,
		},
		setup(props) {
			const subShow = ref(false);
			const showSubMenu = () => {
				subShow.value = true;
			};
			const hideSubMenu = () => {
				subShow.value = false;
			};
			return {
				showSubMenu,
				hideSubMenu,
				subShow,
			};
		},
	};
</script>

<style scoped>
	.sub-menu {
		position: relative;
		z-index: 999;
	}

	.title {
		position: relative;
		height: 50px;
		line-height: 50px;
		margin-left: 15px;
	}

	.title:hover {
		color: #00cfe8;
	}

	.icon {
		position: absolute;
		right: 15px;
	}

	.sub-item {
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		border-radius: 5px;
	}

	.v-enter-active,
	.v-leave-active {
		transition: all 0.3s ease-in-out;
	}

	.v-enter,
	.v-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}
</style>
