<template>
	<div class="tree-menu">
		<template v-for="(item, index) in menu">
			<!--      <menu-item v-if="!item.children" :data="item">{{ item.title }}</menu-item>-->
			<re-sub-menu :data="item" class="border-bottom"></re-sub-menu>
		</template>
	</div>
</template>

<script>
	import SubMenu from "./SubMenu";
	import MenuItem from "./MenuItem";
	import ReSubMenu from "./ReSubMenu";

	export default {
		name: "HMenu",
		props: {
			menu: Array,
		},
		components: {
			SubMenu,
			MenuItem,
			ReSubMenu,
		},
	};
</script>

<style scoped>
	.tree-menu {
		border-radius: 5px;
		width: 100%;
	}
</style>
